import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'

var firebaseConfig = {
    apiKey: "AIzaSyAAoi69REFz1ReI7C8ffoYXyZo5eA-qohc",
    authDomain: "funkypeachlife.firebaseapp.com",
    projectId: "funkypeachlife",
    storageBucket: "funkypeachlife.appspot.com",
    messagingSenderId: "232532174244",
    appId: "1:232532174244:web:81bc3c702ef52ec3510d31",
    measurementId: "G-GJJ5VCPH4J"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  // firebase.analytics();

  const projectStorage = firebase.storage()
  const projectFirestore = firebase.firestore()
  const timestamp = firebase.firestore.FieldValue.serverTimestamp


  export {projectFirestore, projectStorage, timestamp}