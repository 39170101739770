import { useState, useEffect } from 'react'
import { projectFirestore } from '../firebase/config'

const useFirestore = (collection) => {
    const [docs, setDocs] = useState([])


    useEffect(() => {
        const unsub = projectFirestore.collection(collection)
        //puts docs in descending date order first
            .orderBy('createdAt', 'desc')
            .onSnapshot((snap) => {
                //this is realtime listener for database updates
                let documents = []
                snap.forEach(doc => {
                    //each document stored in array will have its id and data
                    documents.push({...doc.data(), id: doc.id})
                })
                setDocs(documents)
            })
            //if we unmount the image grid component then we can
        //unsubscribe from this listener by calling this function
            return () => unsub()
    }, [collection])
    

    return { docs }
}

export default useFirestore