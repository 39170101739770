import React, { useEffect, useRef, useState } from 'react'


export default function MapChart({ options, onMount, className, onMountProps }) {

  const ref = useRef()
  const [map, setMap] = useState()

  useEffect(() => {
    const onLoad = () => setMap(new window.google.maps.Map(ref.current, options))
    if (!window.google) {
      const script = document.createElement(`script`)
      script.src =
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyDg0H087A0ZNPsTFtRc0iSEkklJlnpkSR8`
      document.head.append(script)
      script.addEventListener(`load`, onLoad)
      return () => script.removeEventListener(`load`, onLoad)
    } else onLoad()
  }, [options])

  if (map && typeof onMount === `function`) onMount(map, onMountProps)

  return (
    <div
      style={{ height: `60vh`, margin: `1em 0`, borderRadius: `0.5em`, width: `40vw` }}
      {...{ ref, className }}
    />
  )
}


const links = [
    {
        coords: { lat: 33.70162, lng: -80.53688},
        title: "Sparkleberry Swamp",
        url: "connect to firebase post here"
    },
    {
        coords: { lat:  34.2876528, lng: -80.5206318},
        title: "Goodale State Park",
        url: "connect to firebase post here"
    },
    {
        coords: { lat:  34.2498, lng: -81.3285},
        title: "Broad River",
        url: "connect to firebase post here"
    },
    {
        coords: { lat:  31.991607, lng: -80.853498},
        title: "Tybee Island South End",
        url: "connect to firebase post here"
    },
    {
        coords: { lat:  32.016025, lng: -80.891391},
        title: "Lazaretto Creek",
        url: "connect to firebase post here"
    },
    {
        coords: { lat:  32.318660, lng: -81.041493},
        title: "Sgt Jasper State Park",
        url: "connect to firebase post here"
    },
    {
        coords: { lat:  32.38007000, lng: -81.20926400},
        title: "Ebeneezer Creek",
        url: "connect to firebase post here"
    },
    {
        coords: { lat: 33.38173008720789 , lng: -80.09265993783944},
        title: "Santee Canal Lake Moultrie 9-20",
        url: "connect to firebase post here"
    },
    {
        coords: { lat: 33.33275296956599 , lng: -79.98270209551266},
        title: "Bonneau - Lake Moultrie 7-9",
        url: "connect to firebase post here"
    },
    {
        coords: { lat:  33.41778037597396, lng: -80.32269479914974},
        title: "Eutaw Springs Lake Marion 7-5",
        url: "connect to firebase post here"
    },
    {
        coords: { lat: 34.33386827551599, lng: -80.69981738699884},
        title: "Wateree River",
        url: "connect to firebase post here"
    },
    {
        coords: { lat: 33.269305471937386, lng: -80.10314623017015},
        title: "Hatchery Lake Moultrie 6-5",
        url: "connect to firebase post here"
    },
    {
        coords: { lat: 33.8195128017794, lng: -80.78781030970985},
        title: "Congaree Forest - Cedar Creek",
        url: "connect to firebase post here"
    },
    // {
    //     coords: { lat:  , lng: },
    //     title: "",
    //     url: "connect to firebase post here"
    // },
    // {
    //     coords: { lat:  , lng: },
    //     title: "",
    //     url: "connect to firebase post here"
    // },
    // {
    //     coords: { lat:  , lng: },
    //     title: "",
    //     url: "connect to firebase post here"
    // },
    // {
    //     coords: { lat:  , lng: },
    //     title: "",
    //     url: "connect to firebase post here"
    // },
]



function addMarkers(map, links){
    links.forEach((link, index) => {
        const marker = new window.google.maps.Marker({
            map,
            position: link.coords,
            // label: `${index+1}`,
            title: link.title,
        })
        marker.addListener(`click`, () => {
            window.location.href = link.url
        })
    })
}

MapChart.defaultProps = {
  options: {
    center: { lat: 33.5090, lng: -81.0370 },
    zoom: 7.25,
    // mapTypeId: 'hybrid',
    mapTypeControl: false,
    disableDefaultUI: true,
    styles: [
        { elementType: "geometry", stylers: [{ color: "#ebe3cd" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#523735" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#f5f1e6" }] },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [{ color: "#c9b2a6" }],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "geometry.stroke",
          stylers: [{ color: "#dcd2be" }],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [{ color: "#ae9e90" }],
        },
        {
          featureType: "landscape.natural",
          elementType: "geometry",
          stylers: [{ color: "#dfd2ae" }],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [{ color: "#dfd2ae" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#93817c" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [{ color: "#a5b076" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#447530" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#f5f1e6" }],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [{ color: "#fdfcf8" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#f8c967" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#e9bc62" }],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "geometry",
          stylers: [{ color: "#e98d58" }],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "geometry.stroke",
          stylers: [{ color: "#db8555" }],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [{ color: "#806b63" }],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [{ color: "#dfd2ae" }],
        },
        {
          featureType: "transit.line",
          elementType: "labels.text.fill",
          stylers: [{ color: "#8f7d77" }],
        },
        {
          featureType: "transit.line",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#ebe3cd" }],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [{ color: "#dfd2ae" }],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [{ color: "#b9d3c2" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#92998d" }],
        },
      ],    
  },
  onMount: addMarkers,
  onMountProps: links,
}