import React from 'react';
import peach from '../assets/peach.png'
import { motion } from 'framer-motion';

const Title = () => {
  return (
    <div className="title">
        <motion.img src={peach} alt="uploaded pic"
          style={{width: 190, height: 190}}
          animate={{
            scale: [1, 2, 2, 1, 1],
            rotate: [0, 0, 270, 270, 0],
            borderRadius: ["20%", "20%", "50%", "50%", "20%"]
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
            // loop: Infinity,
            repeatDelay: 1
          }}
        />
      <h2>Funky Peach Life</h2>
    </div>
  )
}

export default Title;